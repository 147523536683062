.menu-bar {
  display: flex;
  padding: 20px 40px;
  background-color: #ffffffcc;
  margin-bottom: 0;
  li {
    cursor: pointer;
    padding: 0 32px;
    font-weight: 800;
    list-style-type: none;
    a {
      text-decoration: none;
      color: #282350;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}

.scrollup {
  cursor: pointer;
  position: fixed;
  height: 55px;
  width: 55px;
  top: calc(100vh - 75px);
  right: 20px;
  z-index: 5;
  border-radius: 100%;
  line-height: 55px;
  background-color: #ff6700;
  color:white;
}