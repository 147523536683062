#footer {
  margin-bottom: 0;
  h3 {
    font-size: 16px;
    color: #05071f;
    font-weight: 800;
    padding-bottom: 20px;
    text-align: left;
  }
}

.footer-content {
  .footer-logo {
    text-align: left;
    margin-bottom: 18px;
  }
  p {
    text-align: left;
    max-width: 220px;
    line-height: 1.875;
  }
}

.footer-links {
  ul {
    margin: 0;
    padding: 0;
    li {
      width: 50%;
      float: left;
      text-align: left;
      font-family: 'Poppins';
      font-size: 14px;
      padding-left: 15px;
      margin-bottom: 15px;
      list-style-type: none;
      a {
        position: relative;
        font-weight: 500;
        color: #05071f;
        transition: 0.3s all ease-in-out;
        &::before {
          content: '>';
          position: absolute;
          left: -15px;
          top: 0px;
          font-size: 12px;
          font-weight: 900;
        }
        &:hover {
          color: #0072fd;
          margin-left: 10px;
        }
      }
    }
  }
}

.footer-contacts {
  color: #585476;
  h4 {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    padding-left: 20px;
    margin-right: 15px;
    float: left;
    text-align: left;
    i {
      position: absolute;
      top: 2px;
      left: 0;
      color: #7b0dbd;
      font-size: 16px;
      margin-right: 5px;
    }
    span {
      text-align: left;
      font-weight: 400;
      margin-top: 10px;
      display: block;
      color: #818181;
    }
  }
  .download-btn {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
    text-align: left;
    a {
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .social-icons {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    a {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      background-color: #fff;
      line-height: 30px;
      text-align: center;
      margin-right: 5px;
      transition: 0.3s all ease-in-out;
      box-shadow: 0px 0px 9px 0px rgba(15, 54, 131, 0.07);
      text-decoration: none;

      &:hover {
        opacity: 0.7;
        transform: translateY(5px);
      }
    }

    .fa-facebook {
      background: white;
      color: #3b5998;
    }
    .fa-twitter {
      background: white;
      color: #55acee;
    }
    .fa-instagram {
      background: white;
      color: #8a3ab9;
    }
    .fa-google {
      background: white;
      color: #ea4335;
    }
  }
}

.footer-copyright {
  p {
    float: left;
  }
  .footer-copyright-links {
    float: right;
    a {
      font-weight: 500;
      font-size: 14px;
      margin-left: 35px;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 768px) {
    p {
      width: 100%;
      margin: 8px auto;
    }
    .footer-copyright-links {
      float: left;
      margin-bottom: 8px;
    }
  }
} 
