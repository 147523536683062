.side-bar-icon {
  cursor: pointer;
  font-size: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin: 20px 0;
  border-radius: 5px;
  background: linear-gradient(to bottom right, #33ccff 0%, #ff99cc 100%);
  color: #fff;
}

.side-bar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in;

  .menu-bar-down {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    overflow: auto;
    background-color: #fff;

    .header-logo {
      cursor: pointer;
      padding: 20px 0;
    }

    .menu-cancel-icon {
      cursor: pointer;
      height: 30px;
      width: 30px;
      font-size: 20px;
      padding: 5px;
      margin: 20px 10px;
      border-radius: 50%;
      background: #999b9c;
      color: #fff;
    }
    a {
      cursor: pointer;
      font-weight: 800;
      padding: 20px 0;
      border-top: 1px solid rgb(219, 212, 212);
      color: #282350;
      &:hover {
        border-radius: 5px;
        background-color: red;
        color: #fff;
      }
    }
  }
}
