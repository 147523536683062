.vision-content {
  padding-left: 60px;
  .head,
  .title,
  p {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    .head,
    .title,
    p {
      text-align: center;
    }
  }
}
