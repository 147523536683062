#prepair-delete-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 15px;
    height: 80vh;


    ul {
        list-style: none;

        li {
            span {
                margin-right: 4px;
            }

            a {
                font-weight: bold;
            }
        }
    }
}
