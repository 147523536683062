* {
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

p, ul, a {
  color: #585476;
}

.head {
  color: #282350;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.375;
  padding-top: 0.5rem;
}
.title {
  color: #585476;
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 16px;
}

.container-section {
  margin-bottom: 100px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}