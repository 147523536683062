#privacy-policy {
  padding: 120px 15px;
  h4 {
    text-align: left;
    margin-bottom: 24px;
  }
  p,
  ul li {
    text-align: justify;
  }
  ul {
    padding: 0 40px;
  }
}
