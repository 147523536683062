.header {
  position: absolute;
  z-index: 5;
  width: 100%;
  margin-top: 15px;

  .header-logo {
    float: left;
    padding: 20px 0;
  }
}

.header.header-sticky {
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  margin-top: 0;
  background-color: #fff;
  animation-name: fadeInDown;
  animation-duration: 0.7s;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.8);
  transition: all 0.2s ease-in-out;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
