.banner {
  overflow: hidden;
  .banner-img {
    position: relative;
    right: -250px;
    overflow: hidden;
  }
  .banner-content {
    position: absolute;
    top: 350px;
    left: 100px;

    @media screen and (max-width: 768px) {
      left: 10px;
    }
  }
}
